(function ($) {

    $('document').ready(function() {
        /* use strict */
        'use strict';
        app.init();
    });

    var app = {

        init: function() {
            this.setupHeader();
            this.setupForm();
        },

        setupHeader: function() {
            var header = $('.header-top', 'body');

            $(window)
                .on('scroll', debounce(function() {
                    var fromTop = $('body').scrollTop();
                    header.toggleClass('down', (fromTop > 30));
                }, 10));
        },

        setupForm: function() {
            var _this = this;

            $('form', '.footer-message').submit(function(e) {
                e.preventDefault();
                console.log('form is submitted')

                var $form   = $(this);
                var data    = $form.serialize();
                var method  = $form.attr('method');
                var action  = $form.attr('action');

                $.ajax({
                    'url':      action,
                    'method':   method,
                    'data':     data,
                    'cache':    false,
                    'dataType': 'json',
                    'success':  function(e){
                        $('.form-output span').html(e.message);
                        _this.clearForm();
                    }
                });

            });
        },

        clearForm: function() {
            $('input', 'form').val('');
            $('textarea', 'form').val('');

            setTimeout(function() {
                $('.form-output span').html('Idle.');
            }, 800);
        }
    };
}(jQuery));
